<div class="modal-body">
  <img [appAssetSrc]="'assets/svg/common/' + (isError ? 'warning.svg' : 'success.svg')" />

  <h1>{{ title | translate }}</h1>

  <div
    class="t-align-center"
    innerHtml="{{ text | translate: (textParams ? textParams : {}) }}"
  ></div>

  <div class="buttons-group">
    <button
      class="btn btn-primary submit-btn"
      (click)="activeModal.close()"
    >
      {{ 'common.buttons.ok' | translate }}
    </button>
  </div>
</div>
